import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { COMMUNITY_USER_LINK_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserLink/Base';

export const COMMUNITY_USER_TAG_FULL_FRAGMENT = `
  fragment communityUserTagFullFragment on CommunityUserTag {
    uid
    schemaCode
    name
    user {
      ...communityUserBaseFragment
    }
    links {
      ...communityUserLinkBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${COMMUNITY_USER_LINK_BASE_FRAGMENT}
`;
